<template>
    <v-container class="clientcabin-pricing">
        <v-row justify="center">
            <v-col>
                <p class="text-center pb-4 headline">
                    Let Us Scale Your Organic Traffic.
                    <br />
                    Get Started Today.
                </p>
                <p class="text-subtitle-1">
                    Choose the package which best suits your needs. We highly
                    recommend choosing recurring campaigns as they'll deliver
                    stacked visibility over time, and can offer better pricing.
                </p>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col class="pa-0">
                <table class="pricing-table elevation-10 rounded-lg">
                    <tbody>
                        <tr class="header font-weight-bold sticky">
                            <td width="45%">How Often to Do a Campaign:</td>
                            <td width="15%" align="center">
                                ONE TIME
                                <br />
                                {{ reseller_client_content_campaign_price }}
                            </td>
                            <td width="15%" align="center">
                                MONTHLY
                                <br />
                                {{
                                    reseller_client_recurring_content_campaign_price
                                }}
                            </td>
                            <td width="15%" align="center">
                                WEEKLY
                                <br />
                                {{
                                    reseller_client_recurring_four_content_campaigns_price
                                }}
                            </td>
                        </tr>
                        <tr class="header sticky second-row">
                            <td
                                colspan="1"
                                rowspan="1"
                                class="font-weight-bold"
                            >
                                Recommended for:
                            </td>
                            <td>One off announcement, news or event</td>
                            <td>
                                Increase organic traffic for small site (10
                                pages or less)
                            </td>
                            <td>
                                Increase organic traffic for bigger site
                                (typically over 100 pages)
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Organic Visibility Snapshot Report of
                                Opportunities &amp; Competition
                                <router-link
                                    to="/examples#report"
                                    target="_blank"
                                >
                                    see example
                                </router-link>
                            </td>
                            <td>No</td>
                            <td>Yes</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                Done-For-You News Article
                                <router-link
                                    to="/examples#article"
                                    target="_blank"
                                >
                                    see example
                                </router-link>
                            </td>
                            <td>1x</td>
                            <td>1x/mo</td>
                            <td>4x/mo</td>
                        </tr>
                        <tr>
                            <td>
                                Done-For-You Audio Ad
                                <router-link
                                    to="/examples#audio"
                                    target="_blank"
                                >
                                    see example
                                </router-link>
                            </td>
                            <td>1x</td>
                            <td>1x/mo</td>
                            <td>4x/mo</td>
                        </tr>
                        <tr>
                            <td>
                                Done-For-You Blog Post
                                <router-link
                                    to="/examples#blog"
                                    target="_blank"
                                >
                                    see example
                                </router-link>
                            </td>
                            <td>1x</td>
                            <td>1x/mo</td>
                            <td>4x/mo</td>
                        </tr>
                        <tr>
                            <td>
                                Done-For-You Video Ad
                                <router-link
                                    to="/examples#video"
                                    target="_blank"
                                >
                                    see example
                                </router-link>
                            </td>
                            <td>1x</td>
                            <td>1x/mo</td>
                            <td>4x/mo</td>
                        </tr>
                        <tr>
                            <td>
                                Done-For-You Slideshow
                                <router-link
                                    to="/examples#slideshow"
                                    target="_blank"
                                >
                                    see example
                                </router-link>
                            </td>
                            <td>1x</td>
                            <td>1x/mo</td>
                            <td>4x/mo</td>
                        </tr>
                        <tr>
                            <td>
                                Done-For-You Infographic
                                <router-link
                                    to="/examples#infographic"
                                    target="_blank"
                                >
                                    see example
                                </router-link>
                            </td>
                            <td>1x</td>
                            <td>1x/mo</td>
                            <td>4x/mo</td>
                        </tr>
                        <tr>
                            <td colspan="4" class="font-weight-bold py-4">
                                All This Content Published on over 300 sites
                                including:
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="d-flex align-center">
                                    Google News Approved Sites
                                    <img
                                        src="@/assets/img/clientcabin/proposal/sites/google.png"
                                    />
                                    <img
                                        src="@/assets/img/clientcabin/proposal/sites/daily-herald.png"
                                    />
                                </div>
                            </td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <div class="d-flex align-center">
                                    Local Fox News &amp; Affiliate Sites
                                    <img
                                        src="@/assets/img/clientcabin/proposal/sites/fox.png"
                                    />
                                </div>
                            </td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <div class="d-flex align-center">
                                    Digital Journal
                                    <img
                                        src="@/assets/img/clientcabin/proposal/sites/digital-journal.png"
                                    />
                                </div>
                            </td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <div class="d-flex align-center">
                                    AZ Central (usatoday)
                                    <img
                                        src="@/assets/img/clientcabin/proposal/sites/azcentral-site-masthead.png"
                                    />
                                </div>
                            </td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>100+ Premium News Networks</td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <div class="d-flex align-center">
                                    Box &amp; Dropbox
                                    <img
                                        src="@/assets/img/clientcabin/proposal/sites/dropbox.png"
                                    />
                                </div>
                            </td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <div class="d-flex align-center">
                                    APSense
                                    <img
                                        src="@/assets/img/clientcabin/proposal/sites/apsense.png"
                                    />
                                </div>
                            </td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <div class="d-flex align-center">
                                    Daily Moss
                                    <img
                                        src="@/assets/img/clientcabin/proposal/sites/dm.png"
                                    />
                                </div>
                            </td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <div class="d-flex align-center">
                                    MySpace
                                    <img
                                        src="@/assets/img/clientcabin/proposal/sites/myspace.png"
                                    />
                                </div>
                            </td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <div class="d-flex align-center">
                                    Apple Podcast
                                    <img
                                        src="@/assets/img/clientcabin/proposal/sites/apple-podcast.png"
                                    />
                                </div>
                            </td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <div class="d-flex align-center">
                                    Spotify
                                    <img
                                        src="@/assets/img/clientcabin/proposal/sites/spotify.png"
                                    />
                                </div>
                            </td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <div class="d-flex align-center">
                                    PodBean
                                    <img
                                        src="@/assets/img/clientcabin/proposal/sites/podbean.png"
                                    />
                                </div>
                            </td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <div class="d-flex align-center">
                                    Google Podcast
                                    <img
                                        src="@/assets/img/clientcabin/proposal/sites/google-podcasts.png"
                                    />
                                </div>
                            </td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <div class="d-flex align-center">
                                    ImageShack
                                    <img
                                        src="@/assets/img/clientcabin/proposal/sites/imageshack.png"
                                    />
                                </div>
                            </td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <div class="d-flex align-center">
                                    YouTube
                                    <img
                                        src="@/assets/img/clientcabin/proposal/sites/youtube.png"
                                    />
                                </div>
                            </td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <div class="d-flex align-center">
                                    Vimeo
                                    <img
                                        src="@/assets/img/clientcabin/proposal/sites/vimeo.png"
                                    />
                                </div>
                            </td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <div class="d-flex align-center">
                                    Daily Motion
                                    <img
                                        src="@/assets/img/clientcabin/proposal/sites/daily-motion.png"
                                    />
                                </div>
                            </td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <div class="d-flex align-center">
                                    Slideshare
                                    <img
                                        src="@/assets/img/clientcabin/proposal/sites/slideshare.png"
                                    />
                                </div>
                            </td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <div class="d-flex align-center">
                                    Pinterest
                                    <img
                                        src="@/assets/img/clientcabin/proposal/sites/pinterest.png"
                                    />
                                </div>
                            </td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                <div class="d-flex align-center">
                                    Scopely
                                    <img
                                        src="@/assets/img/clientcabin/proposal/sites/scoopIey.png"
                                    />
                                </div>
                            </td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>
                                Improved Visibility Within Just 30 Days
                                <br />
                                (usually within 1-2 days from launch of
                                campaign)
                            </td>
                            <td>For 1 topic</td>
                            <td>For 1 topic each month</td>
                            <td>For 1 topic each week</td>
                        </tr>
                        <tr>
                            <td>
                                Guaranteed Placement of Business Name, Address,
                                &amp; Phone Number of Hundreds of Major Sites
                                <br />
                                (can help improve Google map placement)
                            </td>
                            <td>Yes</td>
                            <td>Yes (monthly)</td>
                            <td>Yes (weekly)</td>
                        </tr>
                        <tr>
                            <td>Ongoing increases in organic traffic</td>
                            <td>Unlikely</td>
                            <td>Likely</td>
                            <td>Very Likely</td>
                        </tr>
                        <tr>
                            <td>Progress Snapshot Report</td>
                            <td>Not Included</td>
                            <td>Quarterly</td>
                            <td>Monthly</td>
                        </tr>
                        <tr class="sticky buttons-row align-top">
                            <td class="font-weight-bold">Price Campaign:</td>
                            <td class="align-top">
                                <div class="font-weight-bold mb-4">
                                    ONE TIME
                                    <br />
                                    {{ reseller_client_content_campaign_price }}
                                </div>
                                <v-btn
                                    :x-large="$vuetify.breakpoint.mdAndUp"
                                    color="clientcabin-apple white--text"
                                    class="px-2 py-0 px-md-8 py-md-6 rounded-lg"
                                    :class="{
                                        'text-h6': $vuetify.breakpoint.mdAndUp
                                    }"
                                    @click="$emit('order', 'one-off')"
                                >
                                    {{ buyButtonLabel }}
                                </v-btn>
                                <div class="mt-4">
                                    <v-icon small class="mr-1">
                                        $vuetify.icons.secure
                                    </v-icon>
                                    <v-icon class="mr-1">
                                        $vuetify.icons.mastercard
                                    </v-icon>
                                    <v-icon class="mr-1">
                                        $vuetify.icons.amex
                                    </v-icon>
                                    <v-icon class="mr-1">
                                        $vuetify.icons.visa
                                    </v-icon>
                                    <v-icon>$vuetify.icons.paypal</v-icon>
                                </div>
                                <div class="pt-2">
                                    1 campaign effectively costs
                                    {{ reseller_client_content_campaign_price }}
                                </div>
                            </td>
                            <td class="align-top">
                                <div class="font-weight-bold mb-4">
                                    MONTHLY
                                    <br />
                                    {{
                                        reseller_client_recurring_content_campaign_price
                                    }}
                                </div>
                                <v-btn
                                    :x-large="$vuetify.breakpoint.mdAndUp"
                                    color="clientcabin-apple white--text"
                                    class="px-2 py-0 px-md-8 py-md-6 rounded-lg"
                                    :class="{
                                        'text-h6': $vuetify.breakpoint.mdAndUp
                                    }"
                                    @click="$emit('order', 'one-monthly')"
                                >
                                    {{ buyButtonLabel }}
                                </v-btn>
                                <div class="mt-4">
                                    <v-icon small class="mr-1">
                                        $vuetify.icons.secure
                                    </v-icon>
                                    <v-icon class="mr-1">
                                        $vuetify.icons.mastercard
                                    </v-icon>
                                    <v-icon class="mr-1">
                                        $vuetify.icons.amex
                                    </v-icon>
                                    <v-icon class="mr-1">
                                        $vuetify.icons.visa
                                    </v-icon>
                                    <v-icon>$vuetify.icons.paypal</v-icon>
                                </div>
                                <div class="pt-2">
                                    1 campaign effectively costs
                                    {{
                                        reseller_client_recurring_content_campaign_price
                                    }}
                                    and is done once per month
                                </div>
                            </td>
                            <td class="align-top">
                                <div class="font-weight-bold mb-4">
                                    WEEKLY
                                    <br />
                                    {{
                                        reseller_client_recurring_four_content_campaigns_price
                                    }}
                                </div>
                                <v-btn
                                    :x-large="$vuetify.breakpoint.mdAndUp"
                                    color="clientcabin-apple white--text"
                                    class="px-2 py-0 px-md-8 py-md-6 rounded-lg"
                                    :class="{
                                        'text-h6': $vuetify.breakpoint.mdAndUp
                                    }"
                                    @click="$emit('order', 'four-monthly')"
                                >
                                    {{ buyButtonLabel }}
                                </v-btn>
                                <div class="mt-4">
                                    <v-icon small class="mr-1">
                                        $vuetify.icons.secure
                                    </v-icon>
                                    <v-icon class="mr-1">
                                        $vuetify.icons.mastercard
                                    </v-icon>
                                    <v-icon class="mr-1">
                                        $vuetify.icons.amex
                                    </v-icon>
                                    <v-icon class="mr-1">
                                        $vuetify.icons.visa
                                    </v-icon>
                                    <v-icon>$vuetify.icons.paypal</v-icon>
                                </div>
                                <div class="pt-2">
                                    1 campaign effectively costs
                                    {{
                                        effective_reseller_client_recurring_four_content_campaigns_price
                                    }}
                                    and is done weekly
                                    <br />
                                    <span class="font-weight-bold">
                                        (Best Deal)
                                    </span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="12" md="7" class="text-center">
                <p class="font-italic text-subtitle-1">
                    No contracts, cancel any time
                </p>
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { InjectReactive } from '@/utils/decorators';

import type { ClientCabin } from '@/types/ClientCabin';

@Component
export default class Pricing extends Vue {
    @InjectReactive({
        from: 'options',
        default() {
            return false;
        }
    })
    options!: Partial<ClientCabin> | null;

    get reseller_client_content_campaign_price() {
        return this.options?.custom_pricing_available
            ? this.options?.reseller_client_content_campaign_price
            : `${this.format(
                  this.options?.reseller_client_content_campaign_price
              )}`;
    }

    get reseller_client_recurring_content_campaign_price() {
        return this.options?.custom_pricing_available
            ? this.options?.reseller_client_recurring_content_campaign_price
            : `${this.format(
                  this.options?.reseller_client_recurring_content_campaign_price
              )}/mo`;
    }

    get reseller_client_recurring_four_content_campaigns_price() {
        return this.options?.custom_pricing_available
            ? this.options
                  ?.reseller_client_recurring_four_content_campaigns_price
            : `${this.format(
                  this.options
                      ?.reseller_client_recurring_four_content_campaigns_price
              )}/mo`;
    }

    get effective_reseller_client_recurring_four_content_campaigns_price() {
        return this.format(
            Math.round(
                Number(
                    this.options
                        ?.reseller_client_recurring_four_content_campaigns_price
                ) /
                    4 /
                    10
            ) * 10
        );
    }

    get buyButtonLabel() {
        return this.$vuetify.breakpoint.mobile ? 'Buy' : 'Buy now';
    }

    format(price?: number) {
        if (price) {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: this.options?.currency || 'USD',
                maximumFractionDigits: 0
            }).format(price);
        }

        return price;
    }
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

.clientcabin-pricing::v-deep {
    .pricing-table {
        border: 1px solid #e3ecf0;
        border-spacing: 0;
        table-layout: auto;
        width: 100%;

        tr:first-child td:first-child {
            border-top-left-radius: 8px;
        }

        tr:first-child td:last-child {
            border-top-right-radius: 8px;
        }

        tr:last-child td:first-child {
            border-bottom-left-radius: 8px;
        }

        tr:last-child td:last-child {
            border-bottom-right-radius: 8px;
        }

        .sticky {
            position: sticky;
            top: 64px;
            background-color: white;

            &.second-row {
                top: 132px;
            }

            &.buttons-row {
                top: 248px;
            }
        }

        tr {
            td {
                text-align: center;

                &:not(.align-top) {
                    vertical-align: middle;
                }
            }

            td:first-of-type {
                text-align: left;
            }
        }

        tr:nth-child(even) {
            background-color: #fbfbfb;
        }

        tr.header {
            background-color: $clientcabin-darkblue;
            color: white;

            td {
                border-color: $clientcabin-darkblue;
            }
        }

        td {
            padding: 10px 15px;
            vertical-align: top;
            border-right: 1px solid #e3ecf0;
            border-bottom: 1px solid #e3ecf0;

            img:first-of-type {
                margin-left: 10px;
            }

            img + img {
                margin-left: 10px;
            }

            .d-flex {
                flex-wrap: wrap;
            }
        }
    }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
    .clientcabin-pricing::v-deep {
        .pricing-table {
            font-size: 80%;

            td {
                padding: 2px 3px;
            }

            .sticky {
                top: 64px;

                &.second-row {
                    top: 105px;
                }

                &.buttons-row {
                    top: 200px;
                }
            }
        }
    }
}
</style>

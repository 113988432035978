<template>
    <div class="clientcabin-custom-video">
        <div class="video-container">
            <iframe
                title="video"
                :src="embedUrl"
                allowfullscreen
                mozallowfullscreen
                webkitallowfullscreen
                oallowfullscreen
                msallowfullscreen
            ></iframe>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

const ExternalVideoEmbedProps = Vue.extend({
    props: {
        video: {
            type: String,
            default() {
                return '';
            }
        }
    }
});

@Component
export default class ExternalVideoEmbed extends ExternalVideoEmbedProps {
    getYouTubeEmbedCode(url: string) {
        const regExp =
            /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;

        const match = url.match(regExp);

        return match && match[2].length === 11 ? match[2] : null;
    }

    createYouTubeEmbedUrl(code: string) {
        return `https://www.youtube.com/embed/${code}`;
    }

    get embedUrl() {
        const code = this.getYouTubeEmbedCode(this.video);

        if (code) {
            return this.createYouTubeEmbedUrl(code);
        }

        return this.video;
    }
}
</script>

<style lang="scss" scoped>
.clientcabin-custom-video {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;

    .video-container {
        position: relative;
        padding-bottom: 56.25%;
        overflow: hidden;
        max-width: 100%;
        height: auto;
    }

    iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        border: 0;
    }
}
</style>

<template>
    <div class="buyer-journey">
        <div class="journey-steps-wrap">
            <div class="journey-steps" :class="activeClass">
                <div class="head">
                    <img src="./img/head.png" alt="head" />
                </div>
                <div class="step-line">
                    <img
                        class="hide-mbl"
                        src="./img/step-lines.png"
                        alt="step-lines"
                    />
                    <img
                        class="show-mbl"
                        src="./img/step-lines-mbl.png"
                        alt="step-lines-mbl"
                    />
                    <div class="dot-fill dot-1-fill">Dot Fill 1</div>
                    <div class="dot-fill dot-2-fill">Dot Fill 2</div>
                    <div class="dot-fill dot-3-fill">Dot Fill 3</div>
                    <div class="dot-fill dot-4-fill">Dot Fill 4</div>
                    <div class="dot-fill dot-5-fill">Dot Fill 4</div>
                </div>
                <ul>
                    <li>
                        <div class="icon">
                            <img
                                src="./img/icon-megaphone.png"
                                alt="icon-megaphone"
                            />
                        </div>
                        <div class="step-txt">
                            Has a problem, or sees your Ad
                        </div>
                        <div class="dot dot-1" @click="onStep(1)">Dot</div>
                    </li>
                    <li>
                        <div class="icon">
                            <img
                                src="./img/icon-search.png"
                                alt="icon-search"
                            />
                        </div>
                        <div class="step-txt">Researches solutions online</div>
                        <div class="dot dot-2" @click="onStep(2)">Dot</div>
                    </li>
                    <li>
                        <div class="icon">
                            <img
                                src="./img/icon-window.png"
                                alt="icon-window"
                            />
                        </div>
                        <div class="step-txt">Views our content</div>
                        <div class="dot dot-3" @click="onStep(3)">Dot</div>
                    </li>
                    <li>
                        <div class="icon">
                            <img
                                src="./img/icon-shopping-bag.png"
                                alt="icon-shopping-bag"
                            />
                        </div>
                        <div class="step-txt">
                            Proceeds to buy or take action
                        </div>
                        <div class="dot dot-4" @click="onStep(4)">Dot</div>
                    </li>
                    <li>
                        <div class="dot dot-5" @click="onStep(5)">Dot</div>
                    </li>
                    <li>
                        <div class="dot dot-6" @click="onStep(6)">Dot</div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class BuyerJourney extends Vue {
    activeStep = 0;

    get activeClass() {
        return `dot-${this.activeStep}-active`;
    }

    onStep(step: number) {
        this.activeStep = step;
    }
}
</script>

<style lang="scss" scoped>
/* Buyer - Journey */
.buyer-journey {
    width: 100%;
    max-width: 1074px;

    ul {
        list-style: none;
    }
}

.buyer-journey .heading-txt {
    font-size: 28px;
    line-height: 43px;
    text-align: left;
    padding-bottom: 45px;
}

.buyer-journey .heading-txt:last-child {
    padding-bottom: 0;
}

.buyer-journey .heading-txt h2,
.buyer-journey .heading-txt .h2 {
    padding-top: 37px;
}

.buyer-journey .heading-txt h2::before,
.buyer-journey .heading-txt .h2::before {
    left: 0;
    transform: none;
}

.buyer-journey .heading-txt p {
    letter-spacing: 0.01em;
}

.buyer-journey .heading-txt .aside {
    max-width: 990px;
}

.buyer-journey .journey-steps-wrap {
    width: 100%;
    float: left;
}

.buyer-journey .journey-steps {
    width: 100%;
    height: 384px;
    max-width: 1064px;
    float: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    z-index: 1;
}

.buyer-journey .journey-steps .step-line {
    width: 1020px;
    height: 88px;
    float: left;
    position: absolute;
    top: 141px;
    left: 36px;
}

.buyer-journey .journey-steps .step-line img {
    width: 100%;
    position: relative;
    z-index: 1;
}

.buyer-journey .journey-steps .head {
    width: 70px;
    float: left;
    position: absolute;
    top: 142px;
    left: 0;
    z-index: 5;
}

.buyer-journey .journey-steps .head img {
    width: 100%;
}

.buyer-journey .journey-steps .dot-fill {
    width: 0;
    height: 88px;
    float: left;
    background: url('./img/step-lines-fill.png') no-repeat left center;
    text-indent: -9999px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    display: none;
}

.buyer-journey .journey-steps ul {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.buyer-journey .journey-steps ul li {
    width: 120px;
    height: 255px;
    border-left: 1px solid #dfdfdf;
    position: absolute;
    top: 0;
    left: 180px;
}

.buyer-journey .journey-steps ul li::before {
    display: none;
}

.buyer-journey .journey-steps ul li::after {
    content: '';
    width: 100%;
    height: 1px;
    float: left;
    background: #dfdfdf;
    position: absolute;
    bottom: 0;
    left: 0;
}

.buyer-journey .journey-steps ul li .icon {
    width: 52px;
    height: 52px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border: 1px solid #dfdfdf;
    border-radius: 360px;
    position: absolute;
    top: 0;
    left: -26px;
}

.buyer-journey .journey-steps ul li .icon img {
    max-height: 31px;
}

.buyer-journey .journey-steps ul li .step-txt {
    width: 160px;
    float: left;
    font-weight: 700;
    font-size: 16px;
    color: #353535;
    line-height: 19px;
    position: absolute;
    bottom: -2px;
    left: 12px;
    z-index: 5;
    padding: 0 0 12px 0;
}

.buyer-journey .journey-steps ul li .step-txt::before {
    content: '';
    width: 108px;
    height: 6px;
    float: left;
    background: #ed256e;
    border: 1px solid #dfdfdf;
    position: absolute;
    bottom: 0;
    left: 0;
}

.buyer-journey .journey-steps ul li .dot {
    width: 17px;
    height: 17px;
    float: left;
    text-indent: -99999px;
    background: #ed256e;
    border-radius: 360px;
    position: absolute;
    top: 144px;
    left: -9px;
    z-index: 2;
    cursor: pointer;
}

.buyer-journey .journey-steps ul li:nth-child(2) {
    height: 260px;
    top: 52px;
    left: 411px;
}

.buyer-journey .journey-steps ul li:nth-child(2)::after {
    bottom: auto;
    top: 0;
}

.buyer-journey .journey-steps ul li:nth-child(2) .icon {
    top: auto;
    bottom: 0;
}

.buyer-journey .journey-steps ul li:nth-child(2) .icon img {
    max-height: 30px;
}

.buyer-journey .journey-steps ul li:nth-child(2) .step-txt {
    bottom: 257px;
}

.buyer-journey .journey-steps ul li:nth-child(2) .dot {
    top: 94px;
}

.buyer-journey .journey-steps ul li:nth-child(3) {
    height: 313px;
    top: 26px;
    left: auto;
    right: 320px;
}

.buyer-journey .journey-steps ul li:nth-child(3) .icon img {
    max-height: 28px;
}

.buyer-journey .journey-steps ul li:nth-child(3) .dot {
    top: 172px;
}

.buyer-journey .journey-steps ul li:nth-child(4) {
    height: 283px;
    top: auto;
    bottom: 0;
    left: auto;
    right: 120px;
}

.buyer-journey .journey-steps ul li:nth-child(4)::after {
    bottom: auto;
    top: 0;
}

.buyer-journey .journey-steps ul li:nth-child(4) .icon {
    top: auto;
    bottom: 0;
}

.buyer-journey .journey-steps ul li:nth-child(4) .icon img {
    max-height: 34px;
}

.buyer-journey .journey-steps ul li:nth-child(4) .step-txt {
    bottom: 280px;
}

.buyer-journey .journey-steps ul li:nth-child(4) .dot {
    top: 114px;
}

.buyer-journey .journey-steps ul li:nth-child(5) {
    width: 17px;
    border: none;
    top: 180px;
    left: 25px;
}

.buyer-journey .journey-steps ul li:nth-child(5):before,
.buyer-journey .journey-steps ul li:nth-child(5):after {
    display: none;
}

.buyer-journey .journey-steps ul li:nth-child(5) .dot {
    top: 0;
    left: 0;
}

.buyer-journey .journey-steps ul li:nth-child(6) {
    width: 17px;
    border: none;
    top: 175px;
    left: auto;
    right: -5px;
}

.buyer-journey .journey-steps ul li:nth-child(6):before,
.buyer-journey .journey-steps ul li:nth-child(6):after {
    display: none;
}

.buyer-journey .journey-steps ul li:nth-child(6) .dot {
    top: 0;
    left: 0;
}

.buyer-journey .journey-steps .show-mbl {
    display: none;
}

.buyer-journey .journey-steps.dot-1-active .head {
    top: 110px;
    left: 145px;
}

.buyer-journey .journey-steps.dot-1-active .dot-fill {
    width: 120px;
    display: block;
}

.buyer-journey .journey-steps.dot-2-active .head {
    top: 110px;
    left: 380px;
}

.buyer-journey .journey-steps.dot-2-active .dot-fill {
    width: 385px;
    display: block;
}

.buyer-journey .journey-steps.dot-3-active .head {
    top: 165px;
    left: 590px;
}

.buyer-journey .journey-steps.dot-3-active .dot-fill {
    width: 595px;
    display: block;
}

.buyer-journey .journey-steps.dot-4-active .head {
    top: 183px;
    left: 790px;
}

.buyer-journey .journey-steps.dot-4-active .dot-fill {
    width: 795px;
    display: block;
}

.buyer-journey .journey-steps.dot-5-active .head {
    top: 142px;
    left: 0;
}

.buyer-journey .journey-steps.dot-5-active .dot-fill {
    width: 0;
    display: none;
}

.buyer-journey .journey-steps.dot-6-active .head {
    top: 140px;
    left: 1020px;
}

.buyer-journey .journey-steps.dot-6-active .dot-fill {
    width: 1020px;
    display: block;
}

@media (max-width: 1199px) {
    .buyer-journey {
        padding: 60px 0 100px 0;
    }

    .buyer-journey .heading-txt {
        font-size: 24px;
        line-height: 36px;
        padding-bottom: 40px;
    }

    .buyer-journey .heading-txt h2,
    .buyer-journey .heading-txt .h2 {
        padding-top: 32px;
    }

    .buyer-journey .journey-steps-wrap {
        text-align: center;
    }

    .buyer-journey .journey-steps {
        max-width: 680px;
        height: 245px;
        text-align: left;
    }

    .buyer-journey .journey-steps .step-line {
        width: 650px;
        top: 90px;
        left: 24px;
    }

    .buyer-journey .journey-steps .step-line::after,
    .buyer-journey .journey-steps .step-line::before {
        width: 12px;
        height: 12px;
        top: 23px;
        right: -6px;
    }

    .buyer-journey .journey-steps .step-line::before {
        top: 25px;
        left: -6px;
    }

    .buyer-journey .journey-steps .head {
        width: 46px;
        top: 90px;
    }

    .buyer-journey .journey-steps .dot-fill {
        height: 56px;
        background-image: url('./img/step-lines-fill-ipad.png');
    }

    .buyer-journey .journey-steps ul li {
        width: 75px;
        height: 160px;
        left: 115px;
    }

    .buyer-journey .journey-steps ul li .icon {
        width: 35px;
        height: 35px;
        left: -18px;
    }

    .buyer-journey .journey-steps ul li .icon img {
        max-height: 18px;
    }

    .buyer-journey .journey-steps ul li .step-txt {
        width: 110px;
        font-size: 11px;
        line-height: 14px;
        left: 5px;
        padding: 0 0 8px 0;
    }

    .buyer-journey .journey-steps ul li .step-txt::before {
        width: 70px;
        height: 5px;
    }

    .buyer-journey .journey-steps ul li .dot {
        width: 12px;
        height: 12px;
        top: 92px;
        left: -6px;
    }

    .buyer-journey .journey-steps ul li:nth-child(2) {
        height: 166px;
        top: 34px;
        left: 263px;
    }

    .buyer-journey .journey-steps ul li:nth-child(2) .icon img {
        max-height: 18px;
    }

    .buyer-journey .journey-steps ul li:nth-child(2) .step-txt {
        bottom: 163px;
    }

    .buyer-journey .journey-steps ul li:nth-child(2) .dot {
        top: 60px;
    }

    .buyer-journey .journey-steps ul li:nth-child(3) {
        height: 200px;
        top: 15px;
        right: 205px;
    }

    .buyer-journey .journey-steps ul li:nth-child(3) .icon img {
        max-height: 18px;
    }

    .buyer-journey .journey-steps ul li:nth-child(3) .dot {
        top: 111px;
    }

    .buyer-journey .journey-steps ul li:nth-child(4) {
        height: 180px;
        right: 78px;
    }

    .buyer-journey .journey-steps ul li:nth-child(4) .icon img {
        max-height: 20px;
    }

    .buyer-journey .journey-steps ul li:nth-child(4) .step-txt {
        bottom: 177px;
    }

    .buyer-journey .journey-steps ul li:nth-child(4) .dot {
        top: 72px;
    }

    .buyer-journey .journey-steps ul li:nth-child(5) {
        width: 12px;
        top: 113px;
        left: 15px;
    }

    .buyer-journey .journey-steps ul li:nth-child(6) {
        width: 12px;
        top: 112px;
        right: -10px;
    }

    .buyer-journey .journey-steps.dot-1-active .head {
        top: 70px;
        left: 95px;
    }

    .buyer-journey .journey-steps.dot-1-active .dot-fill {
        width: 92px;
    }

    .buyer-journey .journey-steps.dot-2-active .head {
        top: 70px;
        left: 240px;
    }

    .buyer-journey .journey-steps.dot-2-active .dot-fill {
        width: 240px;
    }

    .buyer-journey .journey-steps.dot-3-active .head {
        top: 105px;
        left: 380px;
    }

    .buyer-journey .journey-steps.dot-3-active .dot-fill {
        width: 377px;
    }

    .buyer-journey .journey-steps.dot-4-active .head {
        top: 115px;
        left: 505px;
    }

    .buyer-journey .journey-steps.dot-4-active .dot-fill {
        width: 504px;
    }

    .buyer-journey .journey-steps.dot-5-active .head {
        top: 90px;
        left: 0;
    }

    .buyer-journey .journey-steps.dot-5-active .dot-fill {
        width: 0;
    }

    .buyer-journey .journey-steps.dot-6-active .head {
        top: 90px;
        left: 650px;
    }

    .buyer-journey .journey-steps.dot-6-active .dot-fill {
        width: 650px;
    }
}

@media (max-width: 767px) {
    .buyer-journey {
        padding: 60px 0 100px 0;
    }

    .buyer-journey .heading-txt {
        font-size: 18px;
        line-height: 29px;
        padding-bottom: 40px;
    }

    .buyer-journey .heading-txt h2,
    .buyer-journey .heading-txt .h2 {
        padding-top: 20px;
    }

    .buyer-journey .heading-txt p {
        letter-spacing: normal;
    }

    .buyer-journey .journey-steps {
        height: 700px;
        max-width: 320px;
        margin-top: 50px;
    }

    .buyer-journey .journey-steps .step-line {
        width: 60px;
        top: 0;
        left: 38%;
    }

    .buyer-journey .journey-steps .step-line img {
        width: 100%;
    }

    .buyer-journey .journey-steps .head {
        width: 70px;
        top: -50px;
        left: 40.5%;
    }

    .buyer-journey .journey-steps .dot-fill {
        width: 60px;
        height: 700px;
        background-image: url('./img/step-lines-fill-mbl.png');
        background-position: top center;
        left: 0;
    }

    .buyer-journey .journey-steps ul li {
        width: 240px;
        height: 14px;
        border-left: none;
        top: 15.7%;
        left: 10.7%;
    }

    .buyer-journey .journey-steps ul li::before {
        content: '';
        width: 116px;
        height: 1px;
        float: left;
        display: block;
        background: #dfdfdf;
        position: absolute;
        top: 0;
        left: auto;
        right: 0;
    }

    .buyer-journey .journey-steps ul li::after {
        width: 124px;
        height: 14px;
        background: none;
        border: 1px solid #dfdfdf;
        border-top: none;
        border-left: none;
    }

    .buyer-journey .journey-steps ul li .icon {
        width: 36px;
        height: 36px;
        top: -18px;
        left: auto;
        right: 0;
        z-index: 10;
    }

    .buyer-journey .journey-steps ul li .icon img {
        max-height: 20px;
    }

    .buyer-journey .journey-steps ul li .step-txt {
        width: 110px;
        font-size: 11px;
        line-height: 13px;
        bottom: -2px;
        left: 0;
        padding: 0 0 8px 0;
    }

    .buyer-journey .journey-steps ul li .step-txt::before {
        width: 82px;
        height: 5px;
    }

    .buyer-journey .journey-steps ul li .dot {
        top: -6px;
        left: 133px;
    }

    .buyer-journey .journey-steps ul li:nth-child(2) {
        width: 270px;
        height: 14px;
        top: 37.8%;
        left: 15.5%;
    }

    .buyer-journey .journey-steps ul li:nth-child(2)::before {
        width: 134px;
        top: auto;
        bottom: 0;
    }

    .buyer-journey .journey-steps ul li:nth-child(2)::after {
        width: 136px;
        border: 1px solid #dfdfdf;
        border-bottom: none;
        border-left: none;
    }

    .buyer-journey .journey-steps ul li:nth-child(2) .icon {
        top: -18px;
        bottom: auto;
        right: auto;
        left: 0;
    }

    .buyer-journey .journey-steps ul li:nth-child(2) .icon img {
        max-height: 20px;
    }

    .buyer-journey .journey-steps ul li:nth-child(2) .step-txt {
        top: auto;
        bottom: -2px;
        left: auto;
        right: -28px;
    }

    .buyer-journey .journey-steps ul li:nth-child(2) .dot {
        top: -6px;
        left: 115px;
    }

    .buyer-journey .journey-steps ul li:nth-child(3) {
        width: 240px;
        height: 12px;
        top: auto;
        bottom: 40.3%;
        left: auto;
        left: 0;
        right: auto;
    }

    .buyer-journey .journey-steps ul li:nth-child(3)::after {
        height: 12px;
    }

    .buyer-journey .journey-steps ul li:nth-child(3) .icon img {
        max-height: 18px;
    }

    .buyer-journey .journey-steps ul li:nth-child(3) .dot {
        top: -6px;
        left: 127px;
    }

    .buyer-journey .journey-steps ul li:nth-child(4) {
        width: 248px;
        height: 14px;
        top: auto;
        bottom: 20.6%;
        left: 5.8%;
        right: auto;
    }

    .buyer-journey .journey-steps ul li:nth-child(4)::before {
        width: 86px;
        top: auto;
        bottom: 0;
    }

    .buyer-journey .journey-steps ul li:nth-child(4)::after {
        width: 162px;
        border: 1px solid #dfdfdf;
        border-bottom: none;
        border-left: none;
    }

    .buyer-journey .journey-steps ul li:nth-child(4) .icon {
        top: -18px;
        bottom: auto;
        right: auto;
        left: 0;
    }

    .buyer-journey .journey-steps ul li:nth-child(4) .icon img {
        max-height: 20px;
    }

    .buyer-journey .journey-steps ul li:nth-child(4) .step-txt {
        top: auto;
        bottom: -2px;
        left: auto;
        right: -28px;
    }

    .buyer-journey .journey-steps ul li:nth-child(4) .dot {
        top: -6px;
        left: 97px;
    }

    .buyer-journey .journey-steps ul li:nth-child(5) {
        top: 0;
        left: 45.6%;
    }

    .buyer-journey .journey-steps ul li:nth-child(6) {
        top: auto;
        bottom: -3px;
        right: 48%;
    }

    .buyer-journey .journey-steps.dot-1-active .head {
        top: 70px;
        left: 44.5%;
    }

    .buyer-journey .journey-steps.dot-1-active .dot-fill {
        width: 60px;
        height: 111px;
    }

    .buyer-journey .journey-steps.dot-2-active .head {
        top: 230px;
        left: 44%;
    }

    .buyer-journey .journey-steps.dot-2-active .dot-fill {
        width: 60px;
        height: 266px;
    }

    .buyer-journey .journey-steps.dot-3-active .head {
        top: 370px;
        left: 33%;
    }

    .buyer-journey .journey-steps.dot-3-active .dot-fill {
        width: 60px;
        height: 407px;
    }

    .buyer-journey .journey-steps.dot-4-active .head {
        top: 500px;
        left: 28%;
    }

    .buyer-journey .journey-steps.dot-4-active .dot-fill {
        width: 60px;
        height: 542px;
    }

    .buyer-journey .journey-steps.dot-5-active .head {
        top: -28px;
        left: 40.5%;
    }

    .buyer-journey .journey-steps.dot-5-active .dot-fill {
        width: 60px;
        height: 0px;
    }

    .buyer-journey .journey-steps.dot-6-active .head {
        top: auto;
        bottom: -50px;
        left: 38%;
    }

    .buyer-journey .journey-steps.dot-6-active .dot-fill {
        width: 60px;
        height: 697px;
    }

    .buyer-journey .journey-steps .show-mbl {
        display: block;
    }

    .buyer-journey .journey-steps .hide-mbl {
        display: none;
    }
}
</style>

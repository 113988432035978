var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"buyer-journey"},[_c('div',{staticClass:"journey-steps-wrap"},[_c('div',{staticClass:"journey-steps",class:_vm.activeClass},[_vm._m(0),_vm._m(1),_c('ul',[_c('li',[_vm._m(2),_c('div',{staticClass:"step-txt"},[_vm._v(" Has a problem, or sees your Ad ")]),_c('div',{staticClass:"dot dot-1",on:{"click":function($event){return _vm.onStep(1)}}},[_vm._v("Dot")])]),_c('li',[_vm._m(3),_c('div',{staticClass:"step-txt"},[_vm._v("Researches solutions online")]),_c('div',{staticClass:"dot dot-2",on:{"click":function($event){return _vm.onStep(2)}}},[_vm._v("Dot")])]),_c('li',[_vm._m(4),_c('div',{staticClass:"step-txt"},[_vm._v("Views our content")]),_c('div',{staticClass:"dot dot-3",on:{"click":function($event){return _vm.onStep(3)}}},[_vm._v("Dot")])]),_c('li',[_vm._m(5),_c('div',{staticClass:"step-txt"},[_vm._v(" Proceeds to buy or take action ")]),_c('div',{staticClass:"dot dot-4",on:{"click":function($event){return _vm.onStep(4)}}},[_vm._v("Dot")])]),_c('li',[_c('div',{staticClass:"dot dot-5",on:{"click":function($event){return _vm.onStep(5)}}},[_vm._v("Dot")])]),_c('li',[_c('div',{staticClass:"dot dot-6",on:{"click":function($event){return _vm.onStep(6)}}},[_vm._v("Dot")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"head"},[_c('img',{attrs:{"src":require("./img/head.png"),"alt":"head"}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"step-line"},[_c('img',{staticClass:"hide-mbl",attrs:{"src":require("./img/step-lines.png"),"alt":"step-lines"}}),_c('img',{staticClass:"show-mbl",attrs:{"src":require("./img/step-lines-mbl.png"),"alt":"step-lines-mbl"}}),_c('div',{staticClass:"dot-fill dot-1-fill"},[_vm._v("Dot Fill 1")]),_c('div',{staticClass:"dot-fill dot-2-fill"},[_vm._v("Dot Fill 2")]),_c('div',{staticClass:"dot-fill dot-3-fill"},[_vm._v("Dot Fill 3")]),_c('div',{staticClass:"dot-fill dot-4-fill"},[_vm._v("Dot Fill 4")]),_c('div',{staticClass:"dot-fill dot-5-fill"},[_vm._v("Dot Fill 4")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("./img/icon-megaphone.png"),"alt":"icon-megaphone"}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("./img/icon-search.png"),"alt":"icon-search"}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("./img/icon-window.png"),"alt":"icon-window"}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("./img/icon-shopping-bag.png"),"alt":"icon-shopping-bag"}})])
}]

export { render, staticRenderFns }